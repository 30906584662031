import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import "../styles/theme.scss"
import Layout from "../layouts/layout";
import Podcast from "../components/podcast";

const Podcasts = ({ data, location }) => {
    let global = data.contentfulWebsite;
    return (
        <Layout pathname={location.pathname}>
            <Seo metaTitle={global.title} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
            <div className="o-content o-content-container o-content-container--large">
                <div className="o-wrapper o-container u-text-center">
                    <h1>Podcasts</h1>
                </div>
                {data.allContentfulPodcast.nodes.map((podcast, index) =>
                    <Podcast key={index} podcast={podcast}></Podcast>
                )}
            </div>
        </Layout>
    );
};

export default Podcasts

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "UNINTERRUPTED Canada"}) {
        author
        description
        title
        podcastCtaText
        shareImage {
            file {
                url
            }
        }
    }
    allContentfulPodcast {
        nodes {
            title
            description {
                raw
            }
            img {
                resize(width: 1080) {
                    src
                }
            }
            url {
                url
                service
            }
        }
    }
}
`;
