import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faApple } from '@fortawesome/free-brands-svg-icons'
import { faSpotify } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export default class Card extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="c-podcast">
                <div className="o-wrapper o-container u-flex-center u-flex-center--desktop o-child-spacer">
                    <img alt={this.props.podcast.title} className="c-podcast__thumb" src={this.props.podcast.img.resize.src} />
                    <div className="u-text-center o-content-container">
                        <div className="h4">{this.props.podcast.type}</div>
                        <div className="h1">{this.props.podcast.title}</div>
                        <div>{documentToReactComponents(JSON.parse(this.props.podcast.description.raw))}</div>
                        <div className="o-child-spacer">
                            {this.props.podcast.url.map((service, index) =>
                                <Link className="h1" target="_blank" rel="noreferrer" key={index} to={service.url}>
                                    {service.service === 'Apple' ? <FontAwesomeIcon icon={faApple} /> : ''}
                                    {service.service === 'YouTube' ? <FontAwesomeIcon icon={faYoutube} /> : ''}
                                    {service.service === 'Spotify' ? <FontAwesomeIcon icon={faSpotify} /> : ''}
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};